import {
  Button,
  Card,
  CardContent,
  Form,
  List,
  ListItem,
  ListItemText,
  Loader,
  TextField,
} from '@committed/components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Name } from './Name'
import { WordPosContext } from './WordPos'
import RefreshIcon from '@material-ui/icons/Refresh'

export const App = () => {
  const { wordPos, generate } = useContext(WordPosContext)
  const [names, setNames] = useState<Name[]>([])

  const [firstPrefix, setFirstPrefix] = useState<string>('')
  const [secondPrefix, setSecondPrefix] = useState<string>('')

  const generateNames = useCallback(() => {
    generate(firstPrefix.toLowerCase(), secondPrefix.toLowerCase()).then((l) =>
      setNames(l)
    )
  }, [generate, firstPrefix, secondPrefix])

  useEffect(() => {
    if (wordPos != null) {
      generateNames()
    }
  }, [generateNames, wordPos])

  if (wordPos == null) {
    return <Loader />
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <TextField
              m={3}
              label="First word prefix"
              value={firstPrefix}
              onChange={(e) => setFirstPrefix(e.target.value)}
            />
            <TextField
              m={3}
              label="Second word prefix"
              value={secondPrefix}
              onChange={(e) => setSecondPrefix(e.target.value)}
            />

            <Button
              type="submit"
              startIcon={<RefreshIcon />}
              m={3}
              onClick={generateNames}
            >
              Generate
            </Button>
          </Form>
        </CardContent>
      </Card>
      <Card mt={5}>
        <CardContent>
          <List>
            {names.map((n) => (
              <ListItem key={n.artifact}>
                <ListItemText primary={n.title} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </div>
  )
}
