import React, { useCallback, useEffect, useState } from 'react'
import { Name } from './Name'
import { WordPos, WordPosWindow } from './types'

export interface WordPosContextDefinition {
  wordPos: WordPos | null
  generate(
    firstPrefix?: string,
    secondPrefix?: string,
    count?: number
  ): Promise<Name[]>
}

export const WordPosContext = React.createContext<WordPosContextDefinition>({
  wordPos: null,
  generate: (firstPrefix?: string, secondPrefix?: string, count?: number) =>
    Promise.resolve([]),
})

function cleanWord(word: string): string {
  return word.replace(/_/g, ' ')
}

async function generateNames(
  wordPos: WordPos,
  firstPrefix?: string,
  secondPrefix?: string,
  count: number = 25
): Promise<Name[]> {
  console.log()
  const firsts = await wordPos.randAdjective({
    startsWith: firstPrefix,
    count: count,
  })
  const seconds = await wordPos.randNoun({
    startsWith: secondPrefix,
    count: count,
  })

  const size = Math.max(firsts.length, seconds.length)

  const names: Name[] = []

  for (let i = 0; i < size; i++) {
    const fi = firsts.length > 0 ? i % firsts.length : -1
    const f = cleanWord(fi === -1 ? '' : firsts[fi])
    const si = seconds.length > 0 ? i % seconds.length : -1
    const s = cleanWord(si === -1 ? '' : seconds[si])

    names.push(new Name(f, s))
  }

  return names
}

const wordPosWindow = window as WordPosWindow & typeof globalThis

export const WordPosProvider: React.FC = ({ children }) => {
  const [wordPos, setWordPos] = useState<WordPos | null>(null)

  useEffect(() => {
    const wp = new wordPosWindow.WordPOS({
      // We only need adjectives and nouns
      preload: ['a', 'n'],
      // We don't need the data for our use case
      includeData: false,
      dictPath: 'https://cdn.jsdelivr.net/npm/wordpos-web@1.0.2/dict',
    })

    wp.ready().then(() => {
      console.log('WordPos is ready')
      setWordPos(wp)
    })
  }, [])

  const generate = useCallback(
    (firstPrefix?: string, secondPrefix?: string, count?: number) => {
      if (wordPos != null) {
        return generateNames(wordPos, firstPrefix, secondPrefix, count)
      } else {
        return Promise.resolve([])
      }
    },
    [wordPos]
  )

  return (
    <WordPosContext.Provider value={{ wordPos, generate }}>
      {children}
    </WordPosContext.Provider>
  )
}
