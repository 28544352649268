export class Name {
  constructor(public readonly first: string, public readonly last: string) {}

  get title(): string {
    return `${this.First} ${this.Last}`
  }

  get artifact(): string {
    return `${this.first}-${this.last}`
  }

  get First(): string {
    return this.toTitleCase(this.first)
  }

  get Last(): string {
    return this.toTitleCase(this.last)
  }

  private toCapitalized(s: string): string {
    if (s.length < 2) {
      return s.toUpperCase()
    }

    const u = s.charAt(0).toUpperCase()
    return `${u}${s.slice(1)}`
  }

  private toTitleCase(s: string): string {
    return s.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
